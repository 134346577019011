import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./router/verification";
import "./lib/resizeRootFontSize";
import "./styles/index.less";

import elementUI from "./plugins/element-ui";
Vue.use(elementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
