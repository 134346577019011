import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { requireAuth: true },
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login"),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName: "login" */ "@/views/test.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
