<template>
  <div class="bg-box">
    <div class="scrool">
      <div class="scrool-view">
        <div
          class="link-item"
          v-for="item in list"
          :key="item.id"
          @click="$emit('detail', item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BgBox",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
@import "~@/styles/mixin.less";
.bg-box {
  position: relative;
  width: 7.4rem;
  height: 1.62rem;
  padding: 0.2rem 0.34rem;
  text-align: center;
  color: #ffffff;
  background: url("~@/assets/image/home/border3.png") no-repeat center / 100%
    100%;
  .scrool {
    overflow-x: auto;
    height: 100%;
    display: flex;
    align-items: center;
    .com-scrollbar(6px, #909399);

  }
  .scrool-view {
    min-width: 100%;
    text-align: center;
    white-space: nowrap;

    .link-item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 1.8rem;
      height: 0.54rem;
      line-height: 0.3rem;
      padding: 0.1rem;
      font-size: 0.24rem;
      color: #ffffff;
      background: linear-gradient(90deg, #0076ff 0%, #00baff 100%);
      border-radius: 0.06rem;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        background: linear-gradient(90deg, rgba(0, 118, 255, 0.6) 0%, rgba(0, 186, 255, 0.6) 100%);
      }

      &:not(:last-child) {
        margin-right: 0.4rem;
      }
    }
  }
}
</style>
