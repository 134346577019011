<template>
  <div class="bg-box">
    <el-scrollbar style="height: 100%" wrap-style="overflow-x: hidden;">
      <div
        class="link-item"
        v-for="item in list"
        :key="item.id"
        @click="$emit('detail', item)"
      >
        {{ item.title }}
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "BgBox",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    // handleClick(item) {
    //   const url = item.url;
    //   url && window.open(url);
    // },
  },
};
</script>

<style scoped lang="less">
.bg-box {
  position: relative;
  width: 3.63rem;
  height: 6.32rem;
  padding: 0.5rem 0.22rem;
  text-align: center;
  color: #ffffff;
  background: url("~@/assets/image/home/border2.png") no-repeat center / 100%
    100%;


  .link-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 0.54rem;
    line-height: 0.3rem;
    padding: 0.05rem;
    margin: 0 0.14rem;
    font-size: 0.23rem;
    color: #ffffff;
    background: linear-gradient(90deg, #0076ff 0%, #00baff 100%);
    border-radius: 0.06rem;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      background: linear-gradient(90deg, rgba(0, 118, 255, 0.6) 0%, rgba(0, 186, 255, 0.6) 100%);
    }

    &:not(:last-child) {
      margin-bottom: 0.4rem;
    }
  }
}
</style>
