<template>
  <div class="home-page">
    <div class="head-bar">
      <User class="user-info-box" />
    </div>
    <div class="center-bottom">
      <span class="label">物联上云</span>
      <span class="label">云计算</span>
      <span class="label">3D可视化</span>
      <span class="label">机器人</span>
      <span class="label">区块链</span>
      <span class="label">AGV</span>
      <span class="label">大数据</span>
      <span class="label">数字孪生</span>
      <span class="label">AI人工智能</span>
    </div>
    <div class="center-bottom-arrow-up">
      <div class="animation-arrow-up"></div>
    </div>
    <CenterBox class="center-box" />
    <BgBoxCenter class="center-link" :list="centerList" @detail="handleDetail" />
    <BgBox class="left-link" :list="leftList" @detail="handleDetail" />
    <BgBox class="right-link" :list="rightList" @detail="handleDetail" />
    <Dialog :visible.sync="dialogVisible" :info="selected"/>
  </div>
</template>

<script>
import BgBox from "./components/bg-box/bg-box";
import BgBoxCenter from "./components/bg-box-center/bg-box-center";
import CenterBox from "./components/center-box";
import User from "./components/user-info/user-info";
import Dialog from "./components/dialog/dialog";
import { getProjectList } from "@/api";
import openLink from "@/utils/openLink"
export default {
  name: "Home",
  components: {
    BgBox,
    BgBoxCenter,
    CenterBox,
    Dialog,
    User,
  },
  data() {
    return {
      dialogVisible: false,
      selected: {},
      leftList: [],
      centerList: [],
      rightList: [],
    };
  },
  created() {
    getProjectList().then((res) => {
      const list = res.data?.list || [];
      const dict = {
        1: "leftList",
        2: "centerList",
        3: "rightList",
      };
      list.forEach((ele) => {
        const key = dict[ele.type];
        if (key && this[key]) {
          this[key].push(ele);
        }
      });
    });
  },
  mounted() {},
  methods: {
    handleDetail(item) {
      const { url, admin_url } = item;
      if (url && admin_url) {
        this.showDerail(item)
      } else if (url || admin_url) {
        const link = url || admin_url
        openLink(link)
      }
    },
    showDerail(item) {
      this.selected = item
      this.dialogVisible = true;
    }
  },
};
</script>

<style scoped lang="less">
.home-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #061145 url("~@/assets/image/home/bg.png") no-repeat center /
    cover;

  .head-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.58rem;
    background: url("~@/assets/image/home/head.png") no-repeat center -0.05rem / 106%
      100%;

    .user-info-box {
      position: absolute;
      height: 0.70rem;
      top: 0;
      right: 0;
    }
  }

  .center-box {
    position: absolute;
    bottom: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 9.86rem;
    height: 3.38rem;
  }

  .center-bottom {
    position: absolute;
    bottom: 0.9rem;
    left: 50%;
    transform: translateX(-50%);
    width: 7.8rem;
    height: 6.8rem;
    // background: url("~@/assets/image/home/banner.png") no-repeat center / cover;
    background: url("~@/assets/image/home/banner.gif") no-repeat center 3.22rem /
        100% auto,
      url("~@/assets/image/home/shadow.png") no-repeat 0.28rem 4.86rem / 92% auto;
    z-index: 1;

    .label {
      position: absolute;
      font-size: 0.26rem;
      font-weight: 500;
      letter-spacing: 0.02rem;
      color: #ffffff;
      text-shadow: 0 0 0.2rem #2369ff;

      &:nth-child(1) {
        bottom: 1.48rem;
        left: -0.98rem;
      }

      &:nth-child(2) {
        bottom: 0.72rem;
        left: -0.92rem;
      }

      &:nth-child(3) {
        bottom: -0.05rem;
        left: -0.34rem;
      }

      &:nth-child(4) {
        bottom: -0.44rem;
        left: 1.71rem;
      }

      &:nth-child(5) {
        bottom: -0.54rem;
        left: 3.63rem;
      }

      &:nth-child(6) {
        bottom: -0.44rem;
        right: 1.85rem;
      }

      &:nth-child(7) {
        bottom: -0.05rem;
        right: 0.02rem;
      }

      &:nth-child(8) {
        bottom: 0.72rem;
        right: -1.23rem;
      }

      &:nth-child(9) {
        bottom: 1.48rem;
        right: -1.31rem;
      }
    }
  }
  .center-bottom-arrow-up {
    position: absolute;
    bottom: 0.65rem;
    left: calc(50% - 2.2rem);
    width: 5rem;
    height: 5.14rem;
    z-index: 0;

    .animation-arrow-up {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: showUp 4s linear;
      overflow: hidden;
      background: url("~@/assets/image/home/arrow-up.png") no-repeat center
        bottom / cover;
    }

    @keyframes showUp {
      0% {
        height: 0;
      }
      50% {
        height: 0;
      }
      70% {
        height: 100%;
      }
      100% {
        height: 100%;
      }
    }
  }

  .left-link {
    position: absolute;
    right: calc(50% + 5.75rem);
    top: 2.16rem;
  }
  .right-link {
    position: absolute;
    left: calc(50% + 5.75rem);
    top: 2.16rem;
  }

  .center-link {
    position: absolute;
    top: 1.46rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
