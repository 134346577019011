<template>
  <div class="user-info">
    <img class="avatar" src="@/assets/image/home/avatar.png" />
    <div class="info">
      <div class="name">欢迎您，{{ userInfo.nickname }}</div>
      <div class="part">[冠英数字经济]</div>
    </div>
    <span class="loginout" @click="handleLoginOut">
      <i class="el-icon-switch-button"></i>
      <span>退出登录</span>
    </span>
  </div>
</template>

<script>
import { getCookie, removeCookie, removeToken } from "@/utils/token";
export default {
  name: "UserInfo",
  data() {
    return {
      userInfo: {},
    };
  },
  created() {
    let userInfo = {};
    try {
      userInfo = JSON.parse(getCookie("userInfo")) || {};
    } catch (error) {
      console.log(error);
    }
    this.userInfo = userInfo;
  },
  mounted() {},
  methods: {
    handleLoginOut() {
      removeToken();
      removeCookie("userInfo");
      this.$router.replace({ name: "Login" });
    },
  },
};
</script>

<style scoped lang="less">
.user-info {
  padding-right: 0.6rem;
  display: flex;
  align-items: center;
  color: #68b5ff;
  font-size: 0.14rem;

  .avatar {
    width: 0.38rem;
    height: 0.38rem;
    margin-right: 0.1rem;
  }

  .info {
    position: relative;
    padding-right: 0.15rem;
    margin-right: 0.15rem;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 0.01rem;
      height: 0.32rem;
      background: #68b5ff;
    }

    .part {
      margin-top: 0.06rem;
    }
  }

  .loginout {
    display: flex;
    align-items: center;
    // padding-top: 0.02rem;
    cursor: pointer;
  }

  .el-icon-switch-button {
    font-size: 0.16rem;
    margin-right: 0.07rem;
    font-weight: 700;
  }
}
</style>
