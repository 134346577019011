/**
 * 将token存入cookie中
 */

import Cookies from "js-cookie";

/**
 * 设置Token
 * @param {String} token token
 */
export function setToken(token) {
  Cookies.set("token", token);
}

/**
 * 获取Token
 * @returns {String} token
 */
export function getToken() {
  return Cookies.get("token");
}

/**
 * 移除Token
 */
export function removeToken() {
  Cookies.remove("token");
}

/**
 * 设置Cookie
 */
export function setCookie(key, value) {
  Cookies.set(key, value);
}

/**
 * 获取Cookie
 */
export function getCookie(key) {
  return Cookies.get(key);
}

/**
 * 移除Cookie
 */
export function removeCookie(key) {
  Cookies.remove(key);
}
