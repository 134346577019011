import axios from "@/axios";

export function login(params = {}) {
  return axios.post("/api/app/login", params);
}

export function getProjectList() {
  return axios.get("/api/app/getProjectList");
}

/** 获取图片验证码 */
export function getCaptchaImg() {
  return axios.get("/api/app/getCaptcha?d=" + Date.now(), {
    responseType: "blob",
  });
}
