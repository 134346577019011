import { getToken } from "./token";
export default function openLink(url, target = "_blank") {
  if (!url.includes("http")) {
    window.open(url, target);
  } else if (url) {
    const token = encodeURIComponent(getToken());
    const resUrl = url.includes("?")
      ? `${url}&autoFillToken=${token}`
      : `${url}?autoFillToken=${token}`;
    window.open(resUrl, target);
  }
}
