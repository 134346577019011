<template>
  <div v-show="visible" class="dialog" @click.self="handleClose">
    <div class="title">{{ info.title }}</div>
    <div class="content-box">
      <div class="left" v-if="info.url">
        <el-image class="preview" :src="info.image" fit="cover"></el-image>
        <a class="link-btn" :href="info.url" target="_blank" @click.prevent="handleClick(info.url)">
          点击进入前台页面
        </a>
      </div>
      <div class="right" v-if="info.admin_url">
        <el-image
          class="preview"
          :src="info.admin_image"
          fit="cover"
        ></el-image>
        <a class="link-btn" :href="info.admin_url" target="_blank" @click.prevent="handleClick(info.admin_url)">
          点击进入后台页面
        </a>
      </div>
    </div>
    <i class="el-icon-circle-close close" @click="handleClose"></i>
  </div>
</template>

<script>
import openLink from "@/utils/openLink"
export default {
  name: "Dialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
    handleClick(url) {
      openLink(url)
    },
  },
};
</script>

<style scoped lang="less">
.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;

  .title {
    height: 0.54rem;
    line-height: 0.54rem;
    padding: 0 0.14rem;
    margin-top: 1.67rem;
    color: #ffffff;
    background: linear-gradient(90deg, #0076ff 0%, #00baff 100%);
    border-radius: 0.06rem;
    font-size: 0.24rem;
  }

  .content-box {
    display: flex;
    margin-top: 0.46rem;
    .left,
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 3.8rem;
      margin: 0 0.18rem;
      border: 0.01rem solid #a8e7ff;
      // border-image: linear-gradient(0deg, #a8e7ff, #92c5ff) 10 10;
      background: linear-gradient(0deg, #14337c 0%, #012068 100%);
      border-radius: 0.1rem;
      overflow: hidden;

      .preview {
        width: 2.96rem;
        height: 1.84rem;
        background: #ffffff;
        border-radius: 0.03rem;
        margin-top: 0.3rem;
      }

      .link-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.2rem;
        height: 0.68rem;
        margin-top: 0.28rem;
        margin-bottom: 0.29rem;
        font-size: 0.32rem;
        color: #ffffff;
        border: 0.02rem solid #a8e7ff;
        // border-image: linear-gradient(0deg, #a8e7ff, #92c5ff) 10 10;
        background: linear-gradient(0deg, #0076ff 0%, #00baff 100%);
        border-radius: 0.34rem;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          background: linear-gradient(90deg, rgba(0, 118, 255, 0.6) 0%, rgba(0, 186, 255, 0.6) 100%);
        }
      }
    }

  }

  .close {
    color: #ffffff;
    font-size: 0.4rem;
    margin-top: 0.5rem;
    cursor: pointer;
  }
}
</style>
