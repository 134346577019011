const screenRatioByDesign = 16 / 9;
const docEle = document.documentElement;

function setHtmlFontSize() {
  const width = docEle.clientWidth < 1366 ? 1366 : docEle.clientWidth;
  const height = docEle.clientHeight < 768 ? 768 : docEle.clientHeight;
  const screenRatio = width / height;
  const ratio =
    screenRatio > screenRatioByDesign ? screenRatioByDesign / screenRatio : 1;
  const fontSize = (ratio * width) / 19.2;
  docEle.style.fontSize = fontSize.toFixed(3) + "px";
}
setHtmlFontSize();
window.addEventListener("resize", setHtmlFontSize);
