<template>
  <div class="animation-circle">
    <div class="animation"></div>
    <div class="title">{{ title }}</div>
    <div class="label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: "AnimationCircle",
  props: ["title", "label"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.animation-circle {
  position: relative;
  width: 3.38rem;
  height: 3.38rem;
  .animation {
    width: 100%;
    height: 100%;
    animation: rotateAnimation 20s linear infinite;
    overflow: hidden;
    background: url("~@/assets/image/home/border1.png") no-repeat center / cover;
  }

  .title {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    font-size: 0.38rem;
    text-align: center;
    color: #fefefe;
    text-shadow: 0 0 0.2rem #2369ff;
  }

  .label {
    position: absolute;
    bottom: 27%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.28rem;
    text-align: center;
    color: #fefefe;
    text-shadow: 0 0 0.2rem #2369ff;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -0.2rem;
      width: 0.6rem;
      height: 0.02rem;
      transform: translate(-100%, -50%);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -0.2rem;
      width: 0.6rem;
      height: 0.02rem;
      transform: translate(100%, -50%);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}

@keyframes rotateAnimation {
  to {
    transform: rotate(360deg);
  }
}
</style>
