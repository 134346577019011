<template>
  <div class="center-box">
    <AnimationArrow class="arrow arrow-up" />
    <AnimationArrow class="arrow arrow-left" />
    <AnimationArrow class="arrow arrow-right" />
    <AnimationCircle
      class="circle circle-left"
      title="产业数字化平台"
      label="产业"
    />
    <AnimationCircle
      class="circle circle-center"
      title="数字智能终端"
      label="消费"
    />
    <AnimationCircle
      class="circle circle-right"
      title="公共数字化平台"
      label="政务"
    />
  </div>
</template>

<script>
import AnimationArrow from "./animation-arrow.vue";
import AnimationCircle from "./animation-circle.vue";
export default {
  name: "CenterBox",
  components: {
    AnimationArrow,
    AnimationCircle,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.center-box {
  position: relative;

  .arrow {
    position: absolute;

    &.arrow-up {
      left: 50%;
      top: 0.06rem;
      transform: translate(-50%, -100%);
    }

    &.arrow-left {
      left: 0.36rem;
      top: 50%;
      transform: translate(-100%, -50%) rotate(-90deg);
    }

    &.arrow-right {
      right: 0.36rem;
      top: 50%;
      transform: translate(100%, -50%) rotate(90deg);
    }
  }

  .circle {
    position: absolute;
    top: 0;
    &.circle-left {
      left: 0%;
    }
    &.circle-center {
      left: 50%;
      transform: translateX(-50%);
    }
    &.circle-right {
      right: 0%;
    }
  }
}
</style>
