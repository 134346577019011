/**
 * 权限认证 检查用户权限
 **/
import router from "./index";
import { getToken } from "@/utils/token";

router.beforeEach((to, from, next) => {
  // set page title
  const pageTitle = to.meta?.title
    ? `${to.meta.title} - 冠英集团`
    : "冠英数字经济产业落地示范项目";
  document.title = pageTitle;

  // 该路由需要权限认证
  if (to.meta?.requireAuth) {
    if (!getToken()) {
      next({ name: "Login" });
      return;
    }
    next();
  } else {
    next();
  }
});
