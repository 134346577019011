<template>
  <div class="arrow-box">
    <div class="animation">
    </div>
  </div>
</template>

<script>
export default {
  name: "AnimationArrow",
};
</script>

<style scoped lang="less">
.arrow-box {
  width: 1.3rem;
  height: 0.74rem;
  position: relative;

  .animation {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: show 1s linear;
    overflow: hidden;
    background: url("~@/assets/image/home/arrow-top.png") no-repeat bottom center /
      cover;
  }
}

@keyframes show {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}
</style>
